@import '../../colors.scss';

main#homeContainer {
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .blackdrop {
        height:100vh;
        width: 100vw;
        top: 0;
        left: 0;
        position: absolute;
        background: #00ffeba3;
        z-index: 98;
    }

    div#homePopUpContainer {
        width: 50vw;
        height: fit-content;
        position: absolute;
        z-index: 99;
        background: linear-gradient(120deg, $pink 0%, $indigo 100%);
        top: 10vh;
        border-radius: 50px;
        box-shadow: inset 0px 0px 10px 1px $indigo;
        border: 2px solid $cyan;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
        box-sizing: border-box;

        h1#homePopUpTitle {
            margin-top: 10%;
            font-size: 2.5vw;
            text-align: center;
        }

        div#homePopUpBody {
            font-size: 1.3vw;
            color: black;
            font-weight: bolder;
            margin-bottom: 10%;
        }

        div#homePopUpCloseButton {
            text-align: right;
            top: -3%;
            right: -3%;
            position: absolute;

            svg {
                height: 2vw;
                cursor: pointer;
            }
        }
    }

    img#angelusProductionsLogo {
        height: 33vh;
        animation: angel-fly 1.5s ease-in-out infinite 0s;
    }

    div#servicesContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 33vh;
        margin-bottom: 5%;

        img.service {
            width: 30%;
            cursor: pointer;

            &:nth-of-type(1) {
                animation: cloud-hover 4s ease-in-out infinite 0s;
                &:hover {
                    animation: cloud-hover-hover 4s ease-in-out infinite 0s;                
                }
                &:active {
                    animation: cloud-hover-active 4s ease-in-out infinite 0s;
                }
            }

            &:nth-of-type(2) {
                animation: cloud-hover 4s ease-in-out infinite 1s;
                &:hover {
                    animation: cloud-hover-hover 4s ease-in-out infinite 1s;
                }
                &:active {
                    animation: cloud-hover-active 4s ease-in-out infinite 1s;
                }
            }

            &:nth-of-type(3) {
                animation: cloud-hover 4s ease-in-out infinite 2s;
                &:hover {
                    animation: cloud-hover-hover 4s ease-in-out infinite 2s;
                }
                &:active {
                    animation: cloud-hover-active 4s ease-in-out infinite 2s;
                }
            }
        }
    }
}

@keyframes cloud-hover {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-50px);
    }
}

@keyframes cloud-hover-hover {
    0%, 100% {
        transform: translateY(0) scale(1.05);
    }

    50% {
        transform: translateY(-50px) scale(1.05);
    }
}

@keyframes cloud-hover-active {

    0%,
    100% {
        transform: translateY(0) scale(0.9);
    }

    50% {
        transform: translateY(-50px) scale(0.9);
    }
}

@keyframes angel-fly {
    0%, 100% {
        transform: translateY(0);
    }

    90% {
        transform: translateY(10px);
    }
}