@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@100&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './colors.scss';

* {
    transition: 300ms;
}

body {
    color: $white;
    font-family: 'Fira Sans';
    margin: 0;
    background: $black;
}

a {
    color: $black;
    &:hover {
        color: $black;
        text-decoration: none;
    }
}

li {
    list-style: none;
}

.clickable:hover {
    transform: scale(1.5);
    color: $pink;
}
.clickable:active {
    transform: scale(0.9);
    color: $cyan;
}